<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //
            'id',
            'name',
            { ...helpers.activeBadge, key: 'active', helper: { ...helpers.activeBadge.helper, id: '_active' } } // TODO: really ?
            // TODO: Validate deletables
          ]"
          :config="{
            url: 'admin/property_types',
            route: '/admin/types/property',
            name: 'property_type'
          }"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'PropertyTypes',
  mixins: [crudMixin]
}
</script>
