import viewMixin from './view-mixin'

export default {
  mixins: [viewMixin],
  data() {
    return {
      lists: [],
      view_helpers: {}
    }
  },
  methods: {
    onPreparedHelpers(helpers) {
      //console.log('ON PREPARE', helpers)
      this.view_helpers = { ...this.view_helpers, ...helpers }
    }
    /*prepareHelpers(lists, notify) {
      this.lists = lists
      const helpers = {
        active: { 0: { name: this.$t('Inactive'), color: 'secondary' }, 1: { name: this.$t('Active'), color: 'success' } },
        statuses: { 0: { name: this.$t('Status 0'), color: 'warning' }, 1: { name: this.$t('Status 1'), color: 'danger' } }
      }
      for (const list in lists) {
        helpers[list] = {}
        for (const item of lists[list]) {
          helpers[list][item.id] = item
        }
      }
      if (notify) this.$emit('preparedHelpers', helpers)
      this.view_helpers = helpers
    },

    getHelperItem(id, helper) {
      return (this.view_helpers[helper] || {})[id]
    }*/
  }
}
