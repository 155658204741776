var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //
          'id',
          'name',
          Object.assign({}, _vm.helpers.activeBadge, {key: 'active', helper: Object.assign({}, _vm.helpers.activeBadge.helper, {id: '_active'})}) // TODO: really ?
          // TODO: Validate deletables
        ],"config":{
          url: 'admin/property_types',
          route: '/admin/types/property',
          name: 'property_type'
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }